import React from "react";

export default function DataScienceItem(props){
    const x = props.item;
    return(
        <div>
            <p>
                Sorry, this component has not been implemented fully at this time. Try again later.
            </p>
            <p>
                {x}
            </p>
        </div>
    )
}
